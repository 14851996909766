import React, { Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react';
import ChevronLeftIcon from 'components/icons/ChevronLeftIcon';
import ChevronRightIcon from 'components/icons/ChevronRightIcon';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mobile } from 'helpers/utils/screensizes';
import Link from '../link';

export type BreadcrumbProps = {
  isBreadcrumb?: boolean;
  className?: string;
  listClassName?: string;
  isCategoryNav?: boolean;
};

const Breadcrumb = ({
  children,
  isBreadcrumb = false,
  className = '',
  listClassName,
  isCategoryNav = false,
}: React.PropsWithChildren<BreadcrumbProps>) => {
  const [linkToPrevious, setLinkToPrevious] = useState<string | undefined>(undefined);
  const [isMobileSize] = useMediaQuery(mobile);

  useEffect(() => {
    if (isBreadcrumb) {
      const child = React.Children.toArray(children)[React.Children.count(children) - 2];
      const link = React.isValidElement(child) ? child.props.link : undefined;
      setLinkToPrevious(link);
    }
  }, [children, isBreadcrumb]);

  const scrollerRef = useRef(null);
  const [showArrowLeft, setShowArrowLeft] = useState(false);
  const [showArrowRight, setShowArrowRight] = useState(false);

  useEffect(() => {
    if (scrollerRef && scrollerRef.current) {
      const { scrollWidth, clientWidth } = scrollerRef.current as HTMLElement;
      if (scrollWidth > clientWidth) setShowArrowRight(true);
    }
  }, []);

  const handleScroll = (ev: SyntheticEvent) => {
    const { scrollLeft, clientWidth, scrollWidth } = ev.target as HTMLElement;
    setShowArrowLeft(scrollLeft !== 0);
    setShowArrowRight(scrollLeft + clientWidth < scrollWidth);
  };

  type Side = 'left' | 'right';

  const handleOnClickArrow = (side: Side) => {
    if (scrollerRef && scrollerRef.current) {
      const scroller = scrollerRef.current as HTMLElement;
      const amount = 150;
      scroller.scrollBy({ left: side === 'left' ? -amount : amount, behavior: 'smooth' });
    }
  };

  const ArrowButton: React.FC<{ side: Side }> = ({ side }) => {
    return (
      <button
        className={`shadow-custom absolute top-3 flex h-48 w-48 items-center justify-center rounded-full bg-neutral-5 hover:bg-base-accent-3 ${
          side === 'left' ? 'left-2' : 'right-2' // take shadow into account
        }`}
        onClick={() => handleOnClickArrow(side)}
      >
        {side === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </button>
    );
  };

  return (
    <nav className={`relative mx-16 md:mx-24 lg:mx-20 xl:mx-48 ${className}`} aria-label="Breadcrumb">
      {isCategoryNav && showArrowLeft && <ArrowButton side="left" />}
      <div ref={scrollerRef} onScroll={handleScroll} className="flex items-center overflow-x-auto scrollbar-hide">
        <ol
          role="list"
          className={`flex items-center justify-start whitespace-pre px-2 ${
            isBreadcrumb ? 'flex-wrap gap-4' : 'gap-x-12 gap-y-22'
          } ${listClassName}`}
        >
          {isBreadcrumb ? (
            <>
              {!isMobileSize ? (
                <Link
                  link={linkToPrevious}
                  className="hidden h-32 w-32 shrink-0 items-center rounded-full bg-base-accent-2 p-6 text-12 text-neutral-4 max-sm:flex"
                >
                  <ChevronLeftIcon scale={0.84} />
                </Link>
              ) : (
                React.Children.map(children, (Child, index) => (
                  <Fragment key={Child?.toString() ?? index}>
                    {!(index === React.Children.count(children) - 1) && (
                      <>
                        <li>
                          <button>{Child}</button>
                        </li>
                        <li className="h-15">
                          {index < React.Children.count(children) - 2 ? (
                            <span className="text-neutral-3">
                              <ChevronRightIcon scale={0.67} />
                            </span>
                          ) : null}
                        </li>
                      </>
                    )}
                  </Fragment>
                ))
              )}
            </>
          ) : (
            React.Children.map(children, (Child, index) => (
              <Fragment key={Child?.toString()}>
                <li key={index}>
                  <button>{Child}</button>
                </li>
              </Fragment>
            ))
          )}
        </ol>
      </div>
      {isCategoryNav && showArrowRight && <ArrowButton side="right" />}
    </nav>
  );
};

export default Breadcrumb;
